import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: ${props => (props.compact ? "1200px" : "1440px")};
`;

const Container = ({ children, className, compact }) => (
  <StyledContainer className={className} compact={compact}>
    {children}
  </StyledContainer>
);

export default Container;
