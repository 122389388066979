import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import RouterLink from "../atoms/Link";
import Instagram from "../atoms/icons/Instagram";
import Pinterest from "../atoms/icons/Pinterest";
import Whatsapp from "../atoms/icons/Whatsapp";

const StyledOffCanvasContainer = styled.div`
  position: relative;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: ${props => (props.containerOpen ? 0 : "-100%")};
  width: 100%;
  height: 100%;
  max-width: 260px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    background-color: ${color.body.base};
    z-index: 11;
  }

  @media only screen and (min-width: 350px) {
    max-width: 300px;
  }
`;

const StyledContent = styled.div`
  position: relative;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  background-color: ${color.body.base};
  color: ${color.black.base};
  padding: 30px;
  padding-top: 60px;
`;

const Backdrop = styled.div`
  display: ${props => (props.hideBackdrop ? "none" : "block")};
  transition: opacity 0.2s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 2;
  ${({ containerOpen }) =>
    containerOpen
      ? "visibility: visible; opacity: 1"
      : "visibility: hidden; opacity: 0"};
`;

const MenuItem = styled.li`
  display: block;
  font-size: 18px;
  margin: 0 10px;
`;

const Link = styled(RouterLink)`
  position: relative;
  padding: 5px 0;
  font-family: "Crimson Text", serif;
  color: #7f7c6d;
  font-size: 26px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${color.body.base};
    height: 1px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  > svg {
    width: 20px;
    height: 20px;
    fill: #7f7c6d;
  }
`;

const ExternalLink = styled.a`
  position: relative;
  padding: 5px 0;
  font-family: "Crimson Text", serif;
  color: #7f7c6d;
  font-size: 26px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${color.body.base};
    height: 1px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  > svg {
    width: 20px;
    height: 20px;
    fill: #7f7c6d;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  margin-top: 10px;

  > a {
    margin-right: 10px;
  }
`;

const MenuCanvas = ({
  onClose,
  className,
  aboutOnClick,
  visionOnClick,
  servicesOnClick,
  pricingOnClick,
  buildingOnClick,
  contactOnClick,
  menuOpen,
  toggle
}) => {
  return (
    <>
      <Backdrop onClick={onClose} containerOpen={menuOpen} />
      <StyledOffCanvasContainer className={className} onClose={onClose}>
        <StyledContainer containerOpen={menuOpen}>
          <StyledContent containerOpen={menuOpen}>
            <MenuItem onClick={() => aboutOnClick() || toggle()}>
              <Link href="#">over mij</Link>
            </MenuItem>
            <MenuItem onClick={() => visionOnClick() || toggle()}>
              <Link href="#">mijn visie</Link>
            </MenuItem>
            <MenuItem onClick={() => servicesOnClick() || toggle()}>
              <Link href="#">wat ik doe</Link>
            </MenuItem>
            <MenuItem onClick={() => pricingOnClick() || toggle()}>
              <Link href="#">advies</Link>
            </MenuItem>
            <MenuItem onClick={() => buildingOnClick() || toggle()}>
              <Link href="#">(ver)bouwen</Link>
            </MenuItem>
            <MenuItem onClick={() => contactOnClick() || toggle()}>
              <Link href="#">contact</Link>
            </MenuItem>
            <MenuItem>
              <SocialLinks>
                <ExternalLink
                  href="https://www.instagram.com/livaun.interieur/"
                  target="_blank"
                >
                  <Instagram />
                </ExternalLink>
                <ExternalLink
                  href="https://nl.pinterest.com/livaun/"
                  target="_blank"
                >
                  <Pinterest />
                </ExternalLink>
                <ExternalLink href="https://wa.me/31622582447" target="_blank">
                  <Whatsapp />
                </ExternalLink>
              </SocialLinks>
            </MenuItem>
          </StyledContent>
        </StyledContainer>
      </StyledOffCanvasContainer>
    </>
  );
};
export default MenuCanvas;
