import React from "react";

const Whatsapp = ({ className }) => (
  <svg
    width="420px"
    height="422px"
    viewBox="0 0 420 422"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        d="M358.63,61.58 C319.601397,22.2988584 266.473447,0.274148502 211.1,0.4192802 C96.1,0.4192802 2.59,93.97 2.54,208.96 C2.4937345,245.556366 12.0964223,281.518156 30.38,313.22 L0.79,421.29 L111.35,392.29 C141.925222,408.940926 176.184808,417.666549 211,417.67 L211.09,417.67 C326.03,417.67 419.6,324.11 419.641035,209.12 C419.81482,153.767405 397.847243,100.643387 358.63,61.58 Z M211.1,382.450002 L211,382.450002 C179.955625,382.454113 149.481434,374.109372 122.77,358.29 L116.44,354.53 L50.83,371.74 L68.35,307.74 L64.22,301.18 C46.8858881,273.561888 37.7099235,241.607207 37.7498693,209 C37.7498693,113.43 115.55,35.67 211.17,35.67 C306.907892,35.7252139 384.478671,113.372099 384.440014,209.11 C384.4,304.69 306.64,382.450002 211.1,382.450002 Z M306.18,252.63 C300.97,250.02 275.35,237.41 270.57,235.63 C265.79,233.85 262.32,233.03 258.85,238.24 C255.38,243.45 245.39,255.24 242.35,258.67 C239.31,262.1 236.27,262.59 231.06,259.98 C225.85,257.37 209.06,251.87 189.15,234.11 C173.66,220.3 163.2,203.23 160.15,198.01 C157.1,192.79 159.83,190.01 162.44,187.38 C164.78,185.04 167.65,181.29 170.25,178.25 C172.85,175.21 173.73,173.03 175.46,169.56 C177.19,166.09 176.33,163.04 175.03,160.43 C173.73,157.82 163.31,132.17 158.96,121.73 C154.73,111.57 150.44,112.95 147.24,112.79 C144.24,112.64 140.72,112.6 137.24,112.6 C131.899135,112.744544 126.860906,115.111424 123.34,119.13 C118.57,124.34 105.11,136.95 105.11,162.6 C105.11,188.25 123.78,213.03 126.38,216.51 C128.98,219.99 163.13,272.62 215.38,295.19 C225.082354,299.378199 234.999555,303.05 245.09,306.19 C257.58,310.19 268.94,309.59 277.91,308.25 C287.91,306.76 308.75,295.65 313.09,283.48 C317.43,271.31 317.43,260.87 316.09,258.69 C314.75,256.51 311.39,255.24 306.18,252.63 L306.18,252.63 Z"
        id="Shape"
      ></path>
    </g>
  </svg>
);

export default Whatsapp;
