import React from "react";
import styled from "styled-components";
import ChevronUp from "./icons/ChevronUp";

const StyledScrollToTop = styled.button`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #7f7c6d;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  outline: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const StyledChevronUp = styled(ChevronUp)`
  fill: #f0f0f0;
  width: 20px;
  height: 20px;
`;

const ScrollToTop = ({ onClick }) => (
  <StyledScrollToTop onClick={onClick}>
    <StyledChevronUp />
  </StyledScrollToTop>
);

export default ScrollToTop;
