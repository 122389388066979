import React from "react";
import styled from "styled-components";

const StyledNewStart = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  background-color: #b79b83;

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
`;

const SideElement = styled.div`
  width: 100%;
  background-color: #7f7c6d;
  padding: 30px 20px;

  @media only screen and (min-width: 992px) {
    width: 15%;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/img/Interieuradvies-apeldoorn.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 60%;

  @media only screen and (min-width: 992px) {
    width: 60%;
    padding-top: 40%;
  }

  @media only screen and (min-width: 1800px) {
    padding-top: 35%;
    background-position: center 55%;
  }
`;

const NewStart = ({ className }) => (
  <StyledNewStart className={className}>
    <SideElement />
    <Image />
  </StyledNewStart>
);

export default NewStart;
