import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 38px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }

  img {
    position: absolute;
    left: 160px;
    bottom: -15px;
    max-width: 120px;

    @media only screen and (min-width: 992px) {
      left: 210px;
      bottom: -12px;
      max-width: 130px;
    }

    @media only screen and (min-width: 1024px) {
      left: 200px;
      bottom: -14px;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #cdb8a8;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  background-image: url("/img/Marjella.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  
  @media only screen and (min-width: 992px) {
      width: 50%;
  }
`;

const RightSide = styled.div`
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const InlineLink = styled.button`
  color: #cdb8a8;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-family: "Crimson Text", serif;
  line-height: 1.4rem;
  font-size: 18px;
  padding: 0;
  cursor: pointer;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const About = ({ onClick, aboutScrollRef, buildingLink, adviceLink }) => (
  <>
    <Wrapper ref={aboutScrollRef}>
      <LeftSide></LeftSide>
      <RightSide>
        <TextWrapper>
          <Title>
            Mijn naam is <span>Marjella</span>
          </Title>
          <StyledParagraph>
            Tijd voor vernieuwing in je interieur, maar weet je niet goed waar je moet beginnen? Kun je een duwtje in de juiste richting gebruiken bij het indelen van een ruimte of het kiezen van de juiste kleuren? Tijdens een <InlineLink onClick={adviceLink}>adviesgesprek aan huis</InlineLink> beantwoord ik al je vragen en help ik keuzes te maken rondom de indeling, materialen, kleuren, meubels, accessoires en styling.
            <br />
            <br />
            Ook voor een groot <InlineLink onClick={buildingLink}>(ver)bouwproject</InlineLink> kun je bij mij terecht. Met een gedetailleerd plan adviseer ik je over alle keuzes die hierbij komen kijken.
            <br />
            <br />
            Landelijk, modern, Scandinavisch of vintage, kleurrijk of juist niet; mijn advies pas ik helemaal aan op jouw visie, wensen en persoonlijke stijl. Mijn handschrift kenmerkt zich vooral door een evenwichtig kleur- en materiaalgebruik.
            <br />
            <br />
            Ik vind het een leuke uitdaging om oud en nieuw met elkaar te mixen. Niet alleen omdat het voor een uniek interieur zorgt, maar ook omdat ik bewust om wil gaan met materialen en de wereld om ons heen. Daarnaast zorgen meubels of accessoires met een verhaal voor een interieur dat ‘eigen en vertrouwd’ voelt.
          </StyledParagraph>
          <StyledButton onClick={onClick}>Bekijk wat ik doe</StyledButton>
        </TextWrapper>
      </RightSide>
    </Wrapper>
  </>
);

export default About;
