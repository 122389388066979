import React from "react";
import styled, { css } from "styled-components";

const StyledMenuToggle = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
  user-select: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: ${props => (props.isLandingPage ? "0" : "1")};

  @media only screen and (min-width: 992px) {
    display: none;
  }

  input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;

    ${props =>
      props.menuOpen &&
      css`
        ~ span {
          opacity: 1;
          transform: rotate(45deg) translate(-6px, -6px);
          background-color: #7a8b7c;

          &:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }

          &:nth-last-child(2) {
            opacity: 1;
            transform: rotate(-45deg) translate(1px, 0);
          }
        }
        transform: scale(1, 1);
        right: 0;
      `};
  }

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    background-color: #f0f0f0;
    border-radius: 1px;
    z-index: 1;
    padding: 0;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.35s ease;

    &:first-child {
      transform-origin: 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

const MenuToggle = ({ className, onClick, isLandingPage, menuOpen }) => (
  <StyledMenuToggle
    className={className}
    onClick={onClick}
    menuOpen={menuOpen}
    isLandingPage={isLandingPage}
  >
    <input type="checkbox" />
    <span />
    <span />
    <span />
  </StyledMenuToggle>
);

export default MenuToggle;
