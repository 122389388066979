import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";
import Responsive from "react-responsive";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 38px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #cdb8a8;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }

  span {
    display: block;
    font-weight: 700;
    gap: 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: 992px) {
      flex-direction: row;
  }
`;

const Rightside = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 992px) {
    padding: 100px 0;
    width: 33%;
  }
`;

const Image = styled.img`
  display: none;
  width: 380px;
  height: auto;

  @media only screen and (min-width: 992px) {
    display: block;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: auto;
  }
`;

const Leftside = styled.div`
  width: 100%;
  
  @media only screen and (min-width: 992px) {
    width: 66%;
  }
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    gap: 50px;
  }
`;

const InlineLink = styled.a`
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-family: "Crimson Text", serif;
  line-height: 1.4rem;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  color: #cdb8a8;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Building = ({ buildingScrollRef }) => (
  <>
    <Wrapper ref={buildingScrollRef}>
      <Leftside>
        <TextWrapper>
          <Title>Bouwen of verbouwen?</Title>
          <TextRow>
            <StyledParagraph>
              Wanneer je gaat bouwen of verbouwen is het aan te raden om in een
              vroeg stadium na te denken over het uiteindelijke interieur.
              <br />
              <br />
              Het is vaak lastig om in je voor te stellen hoe een keuze in een
              ontwerpfase er uiteindelijk uit komt te zien. Omdat de ontelbare
              mogelijkheden je kunnen overweldigen, kies je logischerwijs vaak
              de meest bewandelde route. Standaard indelingen voor ruimtes en de
              daarbij horende lichtpunten en onopvallende materialen en kleuren
              (omdat er dan nou eenmaal weinig mis kan gaan). En dat is jammer,
              want wanneer je vanaf nul kunt beginnen, liggen alle mogelijkheden
              open. Je kunt dan écht je eigen droomhuis creëren.
              <br />
              <br />
              Ik kan je helpen een plan te maken dat naadloos aansluit op jouw
              wensen, gezinssamenstelling, leefwijze en natuurlijk stijl. Samen
              met een architect of aannemer kunnen we het ontwerp waar nodig
              optimaliseren. Door korte lijntjes en regelmatig tussentijds contact, gaan we samen stap voor stap door het proces. Achteraf aanpassingen doen is namelijk niet alleen onhandig, maar ook erg kostbaar.
              <br/>
              <br/>
            </StyledParagraph>
            <StyledParagraph>
              <span>Samenwerken</span>
              Ik werk sinds een aantal jaar samen met <InlineLink target="_blank" href="https://dplusb.nl/">D+B
              Architecten</InlineLink>. Zij
              ontwerpen woningen die voldoen aan menselijke maat en behoeften.
              Kennis en innovatie rondom duurzaamheid is wat hun werk kenmerkt.
              Ze willen laten zien dat bio-ecologisch bouwen binnen de reguliere
              budgetten te realiseren is en bovenal het comfort van bewoners
              verhoogt.<br/><br/>
              Ben je op zoek naar een architect? Neem een kijkje op de website. Wanneer je zelf een architect hebt ingeschakeld voor jouw bouw- of verbouwproject, kan ik voor het interieur altijd aansluiten.
              <br/>
              <br/>
              <span>Prijsindicatie</span>
              Ben je benieuwd naar een inschatting van de kosten voor
              interieuradvies (vanaf één etage)? Stuur mij dan een mail met
              foto’s / tekeningen en omschrijf zo duidelijk mogelijk waarmee ik
              je kan helpen.
              <br/>
              <br/>
              Omdat de omvang en het verloop van een project vooraf lastig in te
              schatten zijn, werk niet met vaste prijzen.
            </StyledParagraph>
          </TextRow>
        </TextWrapper>
      </Leftside>
      <Rightside>
        <Image src="/img/Interieuradvies-badkamer.jpg" />
      </Rightside>
    </Wrapper>
  </>
);

export default Building;
