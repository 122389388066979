import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledParagraph = styled.p`
  margin: 0;
  color: ${color.body.base};
  font-family: "Crimson Text", serif;
  line-height: 1.4rem;
  font-size: 16px;
`;

const Paragraph = ({ className, children }) => (
  <StyledParagraph className={className}>{children}</StyledParagraph>
);

export default Paragraph;
