import React from "react";

const MenuIcon = ({ className }) => (
  <svg
    width="69px"
    height="61px"
    viewBox="0 0 69 61"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" strokeWidth="1" fillRule="evenodd">
      <g id="noun_Hamburger-nav_683457" fillRule="nonzero">
        <rect id="Rectangle" x="0" y="0" width="69" height="6.2072333"></rect>
        <rect id="Rectangle" x="0" y="27" width="69" height="6.2072333"></rect>
        <rect
          id="Rectangle"
          x="0"
          y="54.7927667"
          width="69"
          height="6.2072333"
        ></rect>
      </g>
    </g>
  </svg>
);

export default MenuIcon;
