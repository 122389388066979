import React from "react";

const ChevronUp = ({ className }) => (
  <svg
    width="88px"
    height="50px"
    viewBox="0 0 88 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="noun_chevron-up_344378"
        transform="translate(0.250000, 0.000000)"
        fillRule="nonzero"
      >
        <path
          d="M43.75,16.7382812 L43.75,16.7382812 L43.75,16.7382812 L77.7734375,49.3945312 C78.6132812,50.2148438 80,50.1953125 80.859375,49.3554688 L86.8359375,43.515625 C87.6953125,42.6757813 87.7148438,41.3085938 86.875,40.4882813 L45.3320312,0.60546875 C44.9023437,0.17578125 44.3164062,-0.01953125 43.75,0.01953125 C43.1640625,-1.73472348e-18 42.5976562,0.1953125 42.1679687,0.60546875 L0.625,40.4882813 C-0.21484375,41.3085938 -0.1953125,42.6757813 0.6640625,43.515625 L6.640625,49.3554688 C7.5,50.1953125 8.88671875,50.2148438 9.7265625,49.3945312 L43.75,16.7382812 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);

export default ChevronUp;
