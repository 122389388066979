import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import Contact from "../organisms/Contact";
import { Helmet } from "react-helmet";
import OverviewContent from "../organisms/landingpages/OverviewContent";

const Overview = ({ history }) => {
  const contactScrollRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Interieuradvies in omgeving Apeldoorn | Livaun"
        />
        <meta
          name="description"
          content="Wil je een goed en pragmatisch advies voor jouw woonuitdagingen? Dan kom ik graag bij je langs om je op weg te helpen! Apeldoorn, Putten, Amersfoort, Harderwijk, etc."
        />
        <meta
          name="keywords"
          content="interieuradvies gelderland, interieurstylist gelderland, interieurstyling gelderland"
        />
        <title>Interieuradvies in omgeving Apeldoorn | Livaun</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        href="/"
        aboutOnClick={() => history.push("/")}
        visionOnClick={() => history.push("/")}
        servicesOnClick={() => history.push("/")}
        pricingOnClick={() => history.push("/")}
        buildingOnClick={() => history.push("/")}
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <OverviewContent />
      <Contact contactScrollRef={contactScrollRef} />
    </>
  );
};

export default Overview;
