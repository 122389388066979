import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";

const StyledServices = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  background-color: #b79b83;

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (min-width: 992px) {
    min-height: 800px;
  }
`;

const LeftElement = styled.div`
  position: relative;
  width: 100%;
  background-color: #b79b83;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    width: 40%;
    padding: 100px;
  }
  
  @media only screen and (min-width: 1200px) {
    width: 25%; 
  }
`;

const RightElement = styled.div`
  display: none;
  width: 100%;
  background-color: #9d735a;
  padding: 70px 20px;

  @media only screen and (min-width: 1200px) {
    display: block;
    width: 15%;
    padding: 30px 20px;
  }
`;

const Image = styled.div`
  width: 100%;
  background-image: url("/img/Interieuradvies.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;

  @media only screen and (min-width: 992px) {
    width: 60%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media only screen and (min-width: 992px) {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);
    min-width: 450px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 38px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.button`
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-family: "Crimson Text", serif;
  line-height: 1.4rem;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  
  @media only screen and (min-width: 992px) {
      font-size: 16px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Services = ({
  className,
  onClick,
  servicesScrollRef,
  adviceLink,
  buildingLink
}) => (
  <StyledServices className={className} ref={servicesScrollRef}>
    <LeftElement>
      <TextWrapper>
        <Title>Wat ik doe</Title>
        <StyledParagraph>
          <h3>Advies aan huis</h3>
          Tijdens een{" "}
          <InlineLink onClick={adviceLink}>advies aan huis</InlineLink> zorg ik
          niet alleen voor sfeer, maar creëer eenheid en praktische oplossingen.
          Het is mijn doel om jou in korte tijd te inspireren en al je zintuigen
          te prikkelen, zodat je de tools hebt om zelf aan de slag te gaan. Meer
          hulp nodig? Door middel van een moodboard, shoppingpagina, 2D
          indelingsplan of lichtplan kan ik laten zien hoe je jouw interieur -
          zelfstandig en op een haalbare manier - nieuw leven kunt inblazen.
          <br />
          <br />
          <h3>Interieuradvies tijdens (ver)bouwen?</h3>
          Ook bij grote{" "}
          <InlineLink onClick={buildingLink}>
            bouw- of verbouwplannen
          </InlineLink>{" "}
          (vanaf één etage) kan ik helpen om na te denken over de juiste indeling, materiaalkeuzes, verlichting en uiteindelijk natuurlijk het interieur. Anders dan bij een advies aan huis is de uitwerking veel gedetailleerder en uitgebreider. Door in een vroeg stadium aan te haken, kan ik direct meedenken over de keuzes die zich in de eerste fase van het (ver)bouwproces voordoen. Ik breng in kaart wat jullie in je huidige leefsituatie missen, waar behoefte aan is en wat jullie allergrootste wensen zijn. Waar nodig kunnen we plannen dan bijstellen of optimaliseren, zodat je nooit achteraf denkt; hadden we maar…
        </StyledParagraph>
        <StyledButton onClick={onClick}>Bekijk de kosten</StyledButton>
      </TextWrapper>
    </LeftElement>
    <Image />
    <RightElement />
  </StyledServices>
);

export default Services;
