import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import NewStart from "../organisms/NewStart";
import About from "../organisms/About";
import InteriorBannerWithText from "../organisms/InteriorBannerWithText";
import ThreeImages from "../organisms/ThreeImages";
import Contact from "../organisms/Contact";
import { Fade } from "react-reveal";
import Responsive from "react-responsive";
import styled from "styled-components";
import { color } from "../templates/ui";
import ScrollToTop from "../atoms/ScrollToTop";
import { Helmet } from "react-helmet";
import Vision from "../organisms/Vision";
import Services from "../organisms/Services";
import Pricing from "../organisms/Pricing";
import LivingRoomBanner from "../organisms/LivingRoomBanner";
import Building from "../organisms/Building";

const Text = styled.span`
  display: block;
  font-family: "AlexanderLettering Webfont", sans-serif;
  font-size: 42px;
  color: ${color.body.base};
  margin-bottom: 40px;
  margin-left: 20px;
  opacity: 1;
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const Index = () => {
  const aboutScrollRef = useRef(null);
  const visionScrollRef = useRef(null);
  const pricingScrollRef = useRef(null);
  const buildingScrollRef = useRef(null);
  const contactScrollRef = useRef(null);
  const servicesScrollRef = useRef(null);
  const topRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Livaun | Interieuradvies in Harderwijk en omgeving"
        />
        <meta
          name="description"
          content="Tijd voor vernieuwing in je interieur, maar weet je niet goed waar je moet beginnen? Met mijn gevoel voor trends, kleuren en materialen breng ik je interieur helemaal tot leven."
        />
        <meta
          name="keywords"
          content="interieuradvies, interieurstyling, interieurontwerp, harderwijk"
        />
        <title>Livaun | Interieuradvies in Harderwijk en omgeving</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        topRef={topRef}
        aboutOnClick={() =>
          aboutScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        visionOnClick={() =>
          visionScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        servicesOnClick={() =>
          servicesScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        pricingOnClick={() =>
          pricingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        buildingOnClick={() =>
          buildingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <Fade left distance="20px">
      <NewStart />
      </Fade>
      <Fade right distance="20px">
      <About
        aboutScrollRef={aboutScrollRef}
        onClick={() =>
          servicesScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        adviceLink={() =>
          pricingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        buildingLink={() =>
          buildingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      </Fade>
      <Fade left distance="20px">
      <InteriorBannerWithText />
      </Fade>
      <Fade right distance="20px">
      <Vision
        visionScrollRef={visionScrollRef}
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      </Fade>
      <Fade left distance="20px">
      <Services
        servicesScrollRef={servicesScrollRef}
        onClick={() =>
          pricingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        adviceLink={() =>
          pricingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
        buildingLink={() =>
          buildingScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      </Fade>
      <Fade right distance="20px">
        <ThreeImages />
      </Fade>
      <Fade left distance="20px">
      <Pricing
        pricingScrollRef={pricingScrollRef}
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      </Fade>
      <Fade right distance="20px">
      <LivingRoomBanner />
      </Fade>
      <Fade up distance="20px">
      <Building buildingScrollRef={buildingScrollRef} />
      </Fade>
      <Fade left distance="20px">
        <Contact contactScrollRef={contactScrollRef} />
      </Fade>
      <ScrollToTop
        onClick={() =>
          topRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
    </>
  );
};
export default Index;
