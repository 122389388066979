import React from "react";
import styled from "styled-components";
import Container from "../atoms/Container";
import { color } from "../templates/ui";
import { Fade } from "react-reveal";
import FlowerOne from "../atoms/icons/FlowerOne";

const StyledBanner = styled.div`
  width: 100%;
  background-image: url("/img/Interieuradvies-aan-huis.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 75%;
  min-height: 420px;
  
  @media only screen and (min-width: 992px) {
    min-height: 750px;
  }
`;

const InteriorBannerWithText = () => <StyledBanner />;

export default InteriorBannerWithText;
