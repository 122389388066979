import React from "react";
import styled from "styled-components";
import Responsive from "react-responsive";
import Instagram from "../atoms/icons/Instagram";
import Pinterest from "../atoms/icons/Pinterest";
import { color } from "../templates/ui";
import Whatsapp from "../atoms/icons/Whatsapp";

const StyledContact = styled.div`
  flex-direction: column;
  position: relative;
  background-color: #b79b83;

  @media only screen and (min-width: 768px) {
    display: flex;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const SideElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    flex-basis: 50%;
    padding: 0 100px 0 0;
    justify-content: flex-end;
  }
`;

const Title = styled.h2`
  color: #f0f0f0;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-size: 28px;
  text-transform: uppercase;

  @media only screen and (min-width: 992px) {
    font-size: 48px;
  }
`;

const Information = styled.p`
  font-family: "Crimson Text", serif;
  color: #f0f0f0;
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  margin: 0;
  
  @media only screen and (min-width: 992px) {
      font-size: 16px;
  }
`;

const Text = styled.div``;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("/img/Kleuradvies-livaun.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 90%;
  font-weight: 700;

  @media only screen and (min-width: 768px) {
    padding-top: 59%;
    background-position: 20%;
  }

  @media only screen and (min-width: 992px) {
    flex-basis: 50%;
    padding-top: 50%;
  }

  > span {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: #7a8b7c;
    font-family: "Arial Black", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      font-size: 18px;
    }

    @media only screen and (min-width: 992px) {
      font-size: 16px;
      padding: 0;
    }

    span {
      padding-top: 10px;

      @media only screen and (min-width: 768px) {
        display: block;
      }
    }
  }
`;

const Link = styled.a`
  margin-right: 15px;
  text-decoration: none;
  color: #f0f0f0;

  &:last-child {
    margin-right: 0;
  }

  > svg {
    width: 20px;
    height: 20px;
    fill: #f0f0f0;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const AltText = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 14px;
  color: #f0f0f0;
`;

const StyledLink = styled.a`
  position: relative;
  color: #f0f0f0;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${color.body.base};
    height: 1px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const Contact = ({ contactScrollRef }) => (
  <StyledContact ref={contactScrollRef}>
    <SideElement>
      <Text>
        <Title>Contact</Title>
        <Information>
          <strong>Marjella Mijnten</strong>
          <br />
          Interieuradvies
          <br />
          <StyledLink href="/interieuradvies-livaun">
            Harderwijk en omgeving
          </StyledLink>
          <br />
          <br />
          <strong>Adres</strong>
          <br />
          Zijpenbergerhout 49
          <br />
          3845 JT Harderwijk
          <br />
          <br />
          <strong>Kantoor</strong>
          <br />
          Vurenboomweg 6a
          <br />
          3882 LH Putten
          <br />
          <br />
          <StyledLink href="tel:0031622582447" target="_blank">
            06 22 58 24 47
          </StyledLink>
          <br />
          <StyledLink href="mailto:marjella@livaun.nl" target="_blank">
            marjella@livaun.nl
          </StyledLink>
          <br />
          KvK // 70408483
          <br />
          <br />
          <strong>Samen aan de slag?</strong>
          <br />
          Mail mij een aantal foto's van de <br />
          ruimte(s) en omschrijf zo duidelijk
          <br /> mogelijk waarmee ik je kan helpen.
          <br />
          <br />
          <SocialLinks>
            <Link
              href="https://www.instagram.com/livaun.interieur/"
              target="_blank"
            >
              <Instagram />
            </Link>
            <Link href="https://nl.pinterest.com/livaun/" target="_blank">
              <Pinterest />
            </Link>
            <Link href="https://wa.me/31622582447" target="_blank">
              <Whatsapp />
            </Link>
          </SocialLinks>
          <AltText>Design by Livaun</AltText>
        </Information>
      </Text>
    </SideElement>
    <Image />
  </StyledContact>
);

export default Contact;
