import React from "react";
import styled from "styled-components";
import TinySlider from "tiny-slider-react";

const ImageStyle = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  padding-top: calc(500 / 1200 * 100%);

  &:hover {
    transform: scale(1.05);
  }
`;

const FirstImage = styled(ImageStyle)`
  background-image: url("/img/Interieradvies-putten.jpg");
  background-position: right;
  margin-right: 100px;
`;

const SecondImage = styled(ImageStyle)`
  background-image: url("/img/Marjella-livaun.jpg");
  background-position: center;
  transform: scale(1.1);

  &:hover {
    transform: scale(1.15);
  }
`;

const ThirdImage = styled(ImageStyle)`
  background-image: url("/img/Interieuradvies-woonkamer.jpg");
  margin-left: 100px;
`;

const MobileSlider = styled.div`
  display: block;

  @media only screen and (min-width: 992px) {
    display: none;
  }

  .tns-outer {
    > button {
      display: none;
    }
  }

  .tns-controls {
    display: none;
  }
`;

const DesktopItems = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 50px;

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    padding: 130px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    height: 384px;
  }
`;

const settings = {
  lazyload: true,
  nav: false,
  arrows: false,
  mouseDrag: true,
  items: 2,
  autoplay: true,
  speed: 500
};

const ThreeImages = () => (
  <>
    <DesktopItems>
      <FirstImage />
      <SecondImage />
      <ThirdImage />
    </DesktopItems>
    <MobileSlider>
      <TinySlider settings={settings}>
        <Image src="/img/Interieradvies-putten.jpg" alt="" />
        <Image src="/img/Marjella-livaun.jpg" alt="" />
        <Image src="/img/Interieuradvies-woonkamer.jpg" alt="" />
      </TinySlider>
    </MobileSlider>
  </>
);

export default ThreeImages;
