import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import Contact from "../organisms/Contact";
import { Helmet } from "react-helmet";
import InteriorAdviceContentPutten from "../organisms/landingpages/InteriorAdviceContentPutten";

const InteriorAdvicePutten = ({ history }) => {
  const contactScrollRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Op zoek naar Interieuradvies in Putten? | Livaun"
        />
        <meta
          name="description"
          content="Woon je in de omgeving van Putten en word je graag aan huis geadviseerd? Met interieuradvies van Livaun krijg je antwoord op al je woon- en interieurvragen."
        />
        <meta name="keywords" content="interieuradvies putten" />
        <title>Op zoek naar Interieuradvies in Putten? | Livaun</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        href="/"
        aboutOnClick={() => history.push("/")}
        visionOnClick={() => history.push("/")}
        servicesOnClick={() => history.push("/")}
        pricingOnClick={() => history.push("/")}
        buildingOnClick={() => history.push("/")}
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <InteriorAdviceContentPutten
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <Contact contactScrollRef={contactScrollRef} />
    </>
  );
};

export default InteriorAdvicePutten;
