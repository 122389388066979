import React from "react";

const Pinterest = ({ className }) => (
  <svg
    width="390px"
    height="512px"
    viewBox="0 0 390 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="pinterest"
        transform="translate(-1.000000, 0.000000)"
        fillRule="nonzero"
      >
        <path
          d="M61.945312,278.21875 C62.585938,276.621094 68.996094,251.988281 69.445312,250.320312 C59.4375,235.261719 48.320312,185.949219 56.847656,145.921875 C66.046875,87.191406 128.285156,58.320312 187.046875,58.320312 L187.046875,58.210938 C260.617188,58.257812 315.6875,99.191406 315.746094,169.6875 C315.792969,224.867188 282.550781,297.804688 225.789062,297.804688 C225.773438,297.804688 225.761719,297.804688 225.746094,297.804688 C205.488281,297.804688 179.839844,288.617188 173.113281,279.59375 C165.351562,269.195312 163.445312,256.363281 167.546875,242.652344 C177.597656,210.570312 190.414062,172.140625 191.910156,146.515625 C193.296875,122.332031 176.136719,106.597656 153.882812,106.597656 C137.136719,106.597656 115.386719,116.324219 104.546875,143.65625 C95.59375,166.363281 95.785156,190.136719 105.132812,216.328125 C108.777344,226.566406 88.976562,293.3125 82.632812,315.046875 C66.871094,369.039062 45.292969,437.351562 49.90625,475.394531 L54.359375,512 L76.726562,482.6875 C107.679688,442.167969 139.683594,337.355469 148.210938,311.851562 C173.421875,344.5 225.921875,345.4375 232.042969,345.4375 C307.226562,345.4375 392.480469,270.78125 390.0625,166.875 C387.941406,75.753906 321.253906,0 201.429688,0 L201.429688,0.117188 C87.453125,0.117188 20.929688,60.953125 4.644531,137.820312 C-10.269531,209.09375 22.898438,263.339844 61.945312,278.21875 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);

export default Pinterest;
