import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Header from "../organisms/Header";
import Paragraph from "../atoms/Paragraph";
import Container from "../atoms/Container";
import Contact from "../organisms/Contact";

const Title = styled.h1`
  font-size: 28px;
  color: #d1b7a6;
  text-transform: uppercase;
  font-family: "Arial Black", sans-serif;
  margin-bottom: 20px;
  line-height: 2.6rem;
  margin-top: 60px;

  @media only screen and (min-width: 992px) {
    font-size: 42px;
    margin-top: 120px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  margin-bottom: 30px;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  border-bottom: 4px solid #b69c8b;
  background-color: #d1b7a6;
  height: 60px;
  padding: 0 20px;
  color: white;
  font-size: 14px;
  font-family: "Arial Black", sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 60px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 120px;
  }

  &:hover {
    background-color: #b69c8b;
  }
`;

const NotFound = () => {
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Livaun | Interieuradvies, ontwerp & styling"
        />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Header hideMenuToggle />
      <Container compact>
        <Title>Oeps</Title>
        <StyledParagraph>
          Sorry. We hebben ons best gedaan, maar het lijkt erop dat deze pagina
          niet (meer) bestaat of is verhuisd.
        </StyledParagraph>
        <a href="/">
          <StyledButton>Terug naar home</StyledButton>
        </a>
      </Container>
      <Contact />
    </>
  );
};
export default NotFound;
