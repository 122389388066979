import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../templates/ui";

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  color: ${color.body.base};
`;

const RouterLink = ({ href, children, className, active }) => (
  <StyledLink to={href} className={className} active={active}>
    {children}
  </StyledLink>
);

export default RouterLink;
