export const color = {
  primary: {
    hover: "",
    base: ""
  },
  secondary: {
    hover: "",
    base: ""
  },
  body: { base: "#ffffff" },
  base: { base: "" },
  black: { base: "" }
};
export const font = {
  family: {
    base: "",
    head: ""
  },
  base: "16px",
  small: "14px",
  xsmall: "12px",
  large: "18px",
  xlarge: "20px",
  xxlarge: "22px",
  xxxlarge: "24px"
};
